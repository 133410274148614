import {
  Box,
  Button,
  Grid,
  Skeleton,
  Tab,
  Tooltip,
  Typography,
} from "@mui/material";
import ProfileImg from "../../utils/profile_pic.jpg";
import styled from "styled-components";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { useNavigate } from "react-router-dom";
import {
  useAddFamilyMember,
  useAddVehicle,
  useDeleteFamilyMember,
  useDeleteVehicle,
  useGetCustomerRecordsById,
  useGetVehicleDetailsByCustomerId,
  useUpdateCustomerDetails,
  useUpdateVehicleDetailsById,
} from "./service";
import { Add, Delete, Edit } from "@mui/icons-material";
import GlobalModal from "../../components/Modal/modal";
import { useForm } from "react-hook-form";
import DetailsCard from "./components/DetailsCard";
import {
  addMemberSchema,
  addVehicleSchema,
  editMemberSchema,
  editVehicleSchema,
  familyFields,
} from "./fields";
import Swal from "sweetalert2";
import ProfileCardBg from "../../utils/profile_card_bg.jpeg";
import { yupResolver } from "@hookform/resolvers/yup";
import useGetLOBMaster from "../../hooks/useGetLOBMaster";
import VehicleForm from "./components/VehicleForm";
import NoRowsOverlay from "./components/NoRowsOverlay";
import { formatDate } from "../../HelperFunctions/HelperFunctions";
import moment from "moment";
const MyProfile = () => {
  const colorText = "white";
  const [tabSelected, setTabSelected] = useState("1");
  const navigate = useNavigate();
  const customerId = JSON.parse(
    sessionStorage?.getItem("userInfo")
  )?.customerId;
  const { data: customerDataFromApi, isLoading: isLoadingCustomer } =
    useGetCustomerRecordsById({
      id: customerId,
      fetchFamily: "N",
    });
  const { data: familyMembersFromApi } = useGetCustomerRecordsById({
    id: customerId,
    fetchFamily: "Y",
  });
  const familyMembers = familyMembersFromApi?.data?.data;
  const familyHeaders = familyMembersFromApi?.data?.column_head;
  const customerData = customerDataFromApi?.data?.data[0];
  const { mutate: deleteFamilyMember } = useDeleteFamilyMember();
  const { mutate: deleteVehicle } = useDeleteVehicle();
  const fullName = `${customerData?.firstName || ""} ${
    customerData?.lastName || ""
  }`;
  const familyColumns = familyHeaders?.map((header) => {
    return {
      headerName: header.header,
      field: header.accessorKey,
      flex: 1,
    };
  });
  familyColumns?.push({
    field: "actions",
    headerName: "Actions",
    flex: 1,
    headerAlign: "center",
    renderCell: (params) => (
      <Grid
        container
        alignItems="center"
        justifyContent="flex-end"
        display="flex"
        flexDirection="row"
        xs={12}
        md={8}
      >
        <Grid item xs={6} md={5}>
          <Tooltip title="Edit">
            <Button
              onClick={() => {
                setSelectedFamilyMember(
                  familyMembers?.filter((member) => {
                    return member.customerId === params.id;
                  })
                );
                setShowModal(true);
                setModalTitle("Edit Family Member");
                setFormKey("edit_member");
                setCurrentSchema(editMemberSchema);
              }}
              startIcon={<Edit />}
              sx={{ color: "344BFD" }}
            ></Button>
          </Tooltip>
        </Grid>
        <Grid xs={6} md={5}>
          <Tooltip title="Delete">
            <Button
              onClick={() => {
                Swal.fire({
                  title: "Are you sure?",
                  text: "You won't be able to revert this!",
                  icon: "warning",
                  showCancelButton: true,
                  confirmButtonColor: "#3085d6",
                  cancelButtonColor: "#d33",
                  confirmButtonText: "Yes, delete it!",
                }).then((result) => {
                  if (result.isConfirmed) {
                    deleteFamilyMember(params.id);
                  }
                });
              }}
              startIcon={<Delete />}
              sx={{ color: "red" }}
            ></Button>
          </Tooltip>
        </Grid>
      </Grid>
    ),
  });
  let familyRows;
  if (Array.isArray(familyMembers)) {
    familyRows = familyMembers?.map((member) => {
      let row = { id: member?.customerId };
      familyColumns?.map((column) => {
        if (member[column?.field]) {
          if (column?.field === "dob") {
            row = {
              ...row,
              [column?.field]: formatDate(member[column?.field]),
            };
            return;
          }
          row = { ...row, [column?.field]: member[column?.field] };
        }
      });
      return row;
    });
  }

  const { data: vehicleDetailsFromApi } =
    useGetVehicleDetailsByCustomerId(customerId);
  const vehicleDetails = vehicleDetailsFromApi?.data?.data;

  const vehicleHeaders = vehicleDetailsFromApi?.data?.column_head;
  const { data: LOBData } = useGetLOBMaster();
  const lobData = LOBData?.data?.data;
  // const vehicleColumns = vehicleHeaders?.map((header) => {
  //   return {
  //     headerName: header.header,
  //     field: header.accessorKey,
  //     flex: 1,
  //   };
  // });
  const vehicleColumns = [
    {
      headerName: "LOB",
      field: "lob",
      flex: 1,
    },
    {
      headerName: "Engine Number",
      field: "engineNumber",
      flex: 1,
    },
    {
      headerName: "Seating Capacity",
      field: "seatingCapacity",
      flex: 1,
    },
    {
      headerName: "Chassis Number",
      field: "chassisNumber",
      flex: 1,
    },
    {
      headerName: "Fuel Type",
      field: "fueltype",
      flex: 1,
    },
  ];
  vehicleColumns?.push({
    headerName: "Actions",
    field: "actions",
    flex: 1,
    headerAlign: "center",
    renderCell: (params) => (
      <Grid
        container
        alignItems="center"
        justifyContent="flex-end"
        display="flex"
        flexDirection="row"
        xs={12}
        md={8}
      >
        <Grid item xs={6} md={4}>
          <Tooltip title="Edit">
            <Button
              onClick={() => {
                setSelectedVehicle(
                  vehicleDetails?.filter(
                    (vehicle) =>
                      vehicle["customerVehicleDetailsId"] === params.id
                  )
                );
                setShowModal(true);
                setModalTitle("Edit Vehicle");
                setFormKey("edit_vehicle");
                setCurrentSchema(editVehicleSchema);
              }}
              startIcon={<Edit />}
              sx={{ color: "344BFD" }}
            ></Button>
          </Tooltip>
        </Grid>
        <Grid xs={6} md={4}>
          <Tooltip title="Delete">
            <Button
              onClick={() => {
                Swal.fire({
                  title: "Are you sure?",
                  text: "You won't be able to revert this!",
                  icon: "warning",
                  showCancelButton: true,
                  confirmButtonColor: "#3085d6",
                  cancelButtonColor: "#d33",
                  confirmButtonText: "Yes, delete it!",
                }).then((result) => {
                  if (result.isConfirmed) {
                    deleteVehicle(params.id);
                  }
                });
              }}
              startIcon={<Delete />}
              sx={{ color: "red" }}
            ></Button>
          </Tooltip>
        </Grid>
      </Grid>
    ),
  });

  let vehicleRows;
  if (Array.isArray(vehicleDetails)) {
    vehicleRows = vehicleDetails?.map((vehicle) => {
      let row = { id: vehicle?.customerVehicleDetailsId };
      vehicleColumns?.map((column) => {
        if (vehicle[column?.field]) {
          row = { ...row, [column?.field]: vehicle[column?.field] };
        }
      });
      return row;
    });
  }
  // console.log({ lobData })
  const LOBObj = lobData?.filter((item) =>
    ["Bike", "Car", "GCV", "PCV"].includes(item?.lob)
  );
  // console.log(LOBObj)
  const vehicleFields = vehicleHeaders?.map((field) => {
    return {
      label: field.header,
      name: field.accessorKey,
      type: field.type.toLowerCase(),
    };
  });
  vehicleFields?.unshift(
    {
      label: "LOB",
      name: "lob",
      type: "select",
      options: lobData?.map((lob) => lob.lob),
    },
    {
      label: "Vehicle Make",
      name: "make",
      type: "text",
    },
    {
      label: "Vehicle Model",
      name: "model",
      type: "text",
    },
    {
      label: "Vehicle Varient",
      name: "version",
      type: "text",
    },
    {
      label: "Fuel Type",
      name: "fueltype",
      type: "select",
      options: ["PETROL", "DIESEL"],
    },
    {
      label: "Vehicle Registration No",
      name: "vehicleRegNo",
      type: "text",
    },
    { label: "CC/Kwh", name: "kwh", type: "number" },
    {
      label: "Vehicle Id",
      name: "customerVehicleDetailsId",
      type: "hidden",
    }
  );
  const [selectedFamilyMemberData, setSelectedFamilyMemberData] = useState();
  const [selectedVehicleData, setSelectedVehicleData] = useState();
  const [selectedVehicle, setSelectedVehicle] = useState();
  // console.log(selectedVehicle)
  const { mutate } = useAddFamilyMember();

  const submitFamilyMember = (data) => {
    const familyObject = data["add_member"];
    const payload = { ...familyObject };
    mutate(payload);
    setShowModal(false);
  };
  const { mutate: updateFamilyMember } = useUpdateCustomerDetails();
  const { mutate: updateVehicleDetails } = useUpdateVehicleDetailsById();
  const editFamilyMember = (data) => {
    const familyObject = data["edit_member"];
    updateFamilyMember({ id: familyObject.customerId, payload: familyObject });
    setShowModal(false);
  };
  const editVehicle = (data) => {
    const payload = data["edit_vehicle"];
    const vehicleId = selectedVehicle[0]["customerVehicleDetailsId"];
    // console.log(selectedVehicle, vehicleId)
    updateVehicleDetails({ id: vehicleId, payload, customerId });
    setShowModal(false);
  };
  const { mutate: addVehicle } = useAddVehicle();

  const submitVehicle = (data) => {
    const payload = data["add_vehicle"];
    addVehicle([{ customerId, ...payload }]);
    setShowModal(false);
    reset({});
  };

  const [selectedFamilyMember, setSelectedFamilyMember] = useState();
  useEffect(() => {
    if (!selectedFamilyMember) return;
    const data = familyFields?.map((field) => {
      let itemToAdd = {};
      Object.entries(selectedFamilyMember[0])?.map(([key, value]) => {
        if (key === field.name) {
          itemToAdd = { ...field, value };
        }
      });
      return itemToAdd;
    });
    setSelectedFamilyMemberData(data);
  }, [selectedFamilyMember]);
  useEffect(() => {
    if (!selectedVehicle) return;
    const data = vehicleFields?.map((field) => {
      let item = {};
      Object.entries(selectedVehicle[0])?.map(([key, value]) => {
        if (key === field.name) {
          item = { ...field, value };
        }
      });
      return item;
    });
    setSelectedVehicleData(data);
  }, [selectedVehicle]);
  const customerLogo = customerData?.imagePath;
  const [showModal, setShowModal] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [formKey, setFormKey] = useState("");
  const [fieldsData, setFieldsData] = useState([]);
  const [submitHandler, setSubmitHandler] = useState(() => () => {});
  const [currentSchema, setCurrentSchema] = useState([]);
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
    reset,
    trigger,
    control,
    watch,
    clearErrors,
  } = useForm({
    resolver: yupResolver(currentSchema),
    reValidateMode: "onSubmit",
    mode: "onSubmit",
  });
  useEffect(() => {
    if (formKey) {
      switch (formKey) {
        case "add_member":
          break;
        case "add_vehicle":
          break;
        case "edit_member":
          setFieldsData(selectedFamilyMemberData);
          setSubmitHandler(() => editFamilyMember);
          break;
        case "edit_vehicle":
          setFieldsData(selectedVehicleData);
          setSubmitHandler(() => editVehicle);
          break;
      }
    }
  }, [formKey, selectedFamilyMemberData, selectedVehicleData, reset]);

  return (
    <>
      <Grid container style={{ margin: "10px" }}>
        <Grid item xs={12}>
          <TitleStyled color="gray">My Profile</TitleStyled>
        </Grid>
        <Grid item xs={12} sx={{ marginY: "5px" }}>
          <SubTitleStyled>
            Edit Your Personal Info and Contact Details Here
          </SubTitleStyled>
        </Grid>
        <Grid
          item
          xs={12}
          style={{
            backgroundImage: `url(${ProfileCardBg})`,
            backgroundSize: "cover",
            borderRadius: "15px",
            backgroundPosition: "20% 20%",
            marginRight: "20px",
          }}
        >
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={6} sm={4} md={3} lg={2}>
              {isLoadingCustomer ? (
                <Skeleton
                  variant="rounded"
                  sx={{
                    margin: "20px 20px",
                    width: "9rem",
                    height: "9rem",
                    borderRadius: "100%",
                  }}
                />
              ) : (
                <ProfilePic
                  src={customerLogo ? customerLogo : ProfileImg}
                  alt="Profile"
                  style={{ marginLeft: "20px" }}
                />
              )}
            </Grid>
            <Grid item xs={6} sm={5} md={6} lg={8}>
              <Grid container direction="column">
                <Typography
                  variant="body2"
                  style={{
                    color: colorText,
                    fontWeight: "lighter",
                  }}
                >
                  {customerData?.customerRefId}
                </Typography>
                <Typography
                  variant="h6"
                  style={{ color: colorText, fontWeight: "bold" }}
                >
                  {fullName}
                </Typography>
                <Details>
                  {moment(customerData?.dob, "YYYY-MM-DD").format("DD-MM-YYYY")}
                </Details>
                <Details>{customerData?.emailId}</Details>
                <Details>{customerData?.mobileNumber}</Details>
              </Grid>
            </Grid>
            <Grid
              item
              xs={12}
              sm={3}
              md={3}
              lg={2}
              container
              justifyContent={{ xs: "center", sm: "flex-start" }}
              marginTop={{ sm: "2.5rem" }}
              marginBottom={{ xs: "15px", sm: "auto" }}
              marginX="auto"
            >
              <StyledButton
                style={{ marginX: "auto" }}
                borderColor="white"
                color="white"
                borderWidth="1px"
                backgroundColor="transparent"
                onClick={() => {
                  navigate(`/edit-profile/${customerId}`);
                }}
              >
                Edit Details
              </StyledButton>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={12} marginX={"10px"} marginY={"5px"}>
          <TabContext value={tabSelected}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <CustomTabList
                onChange={(event, newValue) => {
                  setTabSelected(newValue);
                }}
              >
                <Tab label={`Family Members`} value="1" />
                <Tab label="Vehicle Details" value="2" />
                <Button
                  variant="outlined"
                  sx={{
                    borderRadius: "5px",
                    marginY: "auto",
                    marginLeft: "auto",
                    marginRight: "2rem",
                    padding: "5px",
                  }}
                  startIcon={<Add />}
                  size="small"
                  onClick={() => {
                    if (tabSelected === "1") {
                      setModalTitle("Add Family Member");
                      setShowModal(true);
                      setFormKey("add_member");
                      setCurrentSchema(addMemberSchema);
                      setFieldsData(familyFields);
                      setSubmitHandler(() => submitFamilyMember);
                    } else if (tabSelected === "2") {
                      setModalTitle("Add Vehicle");
                      setShowModal(true);
                      setFormKey("add_vehicle");
                      setCurrentSchema(addVehicleSchema);
                      // setFieldsData(vehicleFields)
                      // setSubmitHandler(() => submitVehicle)
                      // setSubmitHandler(() => () => {
                      //   console.log("Submitted")
                      // })
                      // setSubmitHandler(() => {
                      //   console.log("Hello")
                      // })
                    }
                  }}
                >
                  <Typography variant="body2">
                    {tabSelected === "1" ? "Add Family Member" : "Add Vehicle "}
                  </Typography>
                </Button>
              </CustomTabList>
            </Box>
            <TabPanel value="1" sx={{ padding: "24px 0px" }}>
              <Grid xs={10} md={12}>
                {familyRows?.length > 0 ? (
                  <DataGrid
                    rows={familyRows || []}
                    columns={familyColumns || []}
                  />
                ) : (
                  <NoRowsOverlay />
                )}
              </Grid>
            </TabPanel>
            <TabPanel value="2" sx={{ padding: "24px 0px" }}>
              <Grid xs={10} md={12}>
                {vehicleRows?.length > 0 ? (
                  <DataGrid
                    rows={vehicleRows || []}
                    columns={vehicleColumns || []}
                  />
                ) : (
                  <NoRowsOverlay />
                )}
              </Grid>
            </TabPanel>
          </TabContext>
        </Grid>
      </Grid>
      <GlobalModal
        open={showModal}
        title={modalTitle}
        onClose={() => {
          setShowModal(false);
          clearErrors();
        }}
      >
        {!["add_vehicle", "edit_vehicle"].includes(formKey) ? (
          <Grid
            container
            component="form"
            onSubmit={handleSubmit(submitHandler)}
          >
            <Grid item xs={12}>
              <DetailsCard
                formKey={formKey}
                Title={modalTitle}
                fields={fieldsData}
                showTitle={false}
                register={register}
                setValue={setValue}
                trigger={trigger}
                errors={errors}
                watch={watch}
                control={control}
              ></DetailsCard>
            </Grid>
            <Grid
              item
              xs={12}
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                margin: "0.75rem",
              }}
            >
              <Button variant="contained" type="submit">
                Submit
              </Button>
            </Grid>
          </Grid>
        ) : (
          <Grid
            container
            component="form"
            onSubmit={handleSubmit(
              formKey === "add_vehicle" ? submitVehicle : editVehicle
            )}
          >
            <Grid item>
              <VehicleForm
                formKey={formKey}
                register={register}
                control={control}
                watch={watch}
                reset={reset}
                setValue={setValue}
                errors={errors}
                trigger={trigger}
                vehicleData={
                  Array.isArray(selectedVehicle) && formKey === "edit_vehicle"
                    ? selectedVehicle[0]
                    : null
                }
              />
            </Grid>
            <Grid
              item
              xs={12}
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                margin: "0.75rem",
              }}
            >
              <Button variant="contained" type="submit">
                Submit
              </Button>
            </Grid>
          </Grid>
        )}
      </GlobalModal>
    </>
  );
};

export default MyProfile;
export const TitleStyled = styled.h5`
  color: ${(props) => props.color || "gray"};
  font-size: ${(props) => props.fontSize || "1.5rem"};
  font-weight: ${(props) => props.fontWeight || "bold"};
  margin-top: 0px;
  margin-bottom: 0px;
`;
export const SubTitleStyled = styled.h6`
  color: ${(props) => props.color || "gray"};
  font-size: ${(props) => props.fontSize || "0.9rem"};
  margin-top: 0px;
  margin-bottom: 0px;
  font-weight: lighter;
`;
export const ProfilePic = styled.img`
  margin: 20px 5px;
  width: ${(props) => props.size || "9rem"};
  height: ${(props) => props.size || "9rem"};
  border-radius: 100%;
  object-fit: cover;
`;

const Details = styled.p`
  color: #ffffff;
  font-size: small;
  font-weight: 100;
  margin: 1.5px;
`;

const StyledButton = styled.button`
  color: ${(props) => props.color || "#3f3f3f"};
  background-color: ${(props) => props.backgroundColor || "white"};
  padding: ${(props) => props.padding || "10px 20px"};
  border-radius: ${(props) => props.borderRadius || "30px"};
  border-color: ${(props) => props.color || "gray"};
  border-style: solid;
  border-width: ${(props) => props.borderWidth || "0.5px"};
  font-weight: bold;
  cursor: pointer;
  max-height: fit-content;
`;

const CustomTabList = styled(TabList)(() => ({
  position: "relative",
  "& .MuiTabs-indicator": {
    height: "3px",
  },
}));
