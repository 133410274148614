import { createSlice } from "@reduxjs/toolkit";

const Theme = createSlice({
  name: "Theme",
  initialState: {
    theme: {
      primaryColor: "#444444", // black
      secondaryColor: "#5A5A5A", // gray
      ascentColor: "#4F46E5", // Soft blue
      bgColor: "#F1F1F1", // light gray
      headingFont: "Manrope",
      regularFont: "Manrope",
    },
  },
  reducers: {
    setTheme: (state, { payload }) => {
      state.theme = payload;
    },
    resetTheme: (state) => {
      state.theme = {
        primaryColor: "#444444", // black
        secondaryColor: "#5A5A5A", // gray
        ascentColor: "#4F46E5", // Soft blue
        bgColor: "#F1F1F1", // light gray
        headingFont: "Manrope",
        regularFont: "Manrope",
      };
    },
  },
});

export const { setTheme, resetTheme } = Theme.actions;
export default Theme.reducer;
