// ProgressBar.js
import React from "react";
import styled from "styled-components";

// Styled components
const ProgressBarContainer = styled.div`
  height: 10px;
  width: 36%;
  background-color: #e0e0e0; /* Light gray background */
  border-radius: 5px; /* Rounded corners for the container */
  overflow: hidden; /* Hide anything outside the rounded corners */
  margin-bottom: 20px;
`;

const Progress = styled.div`
  height: 100%;
  background-color: #3b5998; /* Blue color for the progress bar */
  width: ${({ percentage }) => percentage}%;
  transition: width 0.3s ease; /* Smooth transition for width changes */
`;
const PercentageText = styled.span`
  position: absolute;
  font-size: 12px;
`;

const ProgressBar = ({ current_step_array, cardTitlesPrevPolicyToggle }) => {
  // Ensure cardTitlesPrevPolicyToggle is not empty to avoid division by zero
  const totalSteps = cardTitlesPrevPolicyToggle?.length || 1;
  const currentSteps = current_step_array?.length || 0;

  // Calculate the progress percentage
  const progress = Math.min(
    (currentSteps / totalSteps) * 100,
    100, // Ensure the progress does not exceed 100%
  );

  return (
    <ProgressBarContainer>
      <Progress percentage={progress} />
      <PercentageText>{Math.round(progress)}% Completed</PercentageText>
    </ProgressBarContainer>
  );
};

export default ProgressBar;
