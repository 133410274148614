import { Grid, Tooltip } from "@mui/material";
import moment from "moment";
import React from "react";
import styled from "styled-components";
const RenderViewFields = ({
  label,
  value,
  key,
  isFile,
  isDate,
  isDateAndTime,
  slicedText,
}) => {
  return (
    <>
      <Grid item lg={3} md={4} sm={6} xs={6}>
        <Tooltip title={label?.length > 35 ? key : ""} arrow>
          <StyledLabel>
            {isFile && label?.includes("Upload")
              ? label?.replace("Upload", "Uploaded")
              : label}
          </StyledLabel>
        </Tooltip>
        {!isFile && !isDate && value ? (
          <StyledValue>{slicedText(value) || "- - - - -"}</StyledValue>
        ) : isFile && value ? (
          <DocumentView href={value} target="_blank">
            View Document
          </DocumentView>
        ) : isDate && value ? (
          <StyledValue>
            {moment(value).format("DD-MM-YYYY") || "- - - - -"}
          </StyledValue>
        ) : isDateAndTime && value ? (
          <StyledValue>
            {moment(value).format("DD-MM-YYYY hh:mm a") || "- - - - -"}
          </StyledValue>
        ) : (
          <StyledValue>- - - - -</StyledValue>
        )}
      </Grid>
    </>
  );
};
export default RenderViewFields;
const StyledLabel = styled("label")({
  color: "rgb(17, 85, 95)",
  paddingLeft: "1px",
  fontSize: "12px",
  whiteSpace: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis",
  width: "100%",
  display: "inline-block",
});
const StyledValue = styled("span")({
  gridArea: 1 / 1 / 2 / 3,
  maxWidth: "100%",
  overflow: "hidden",
  textOverflow: "ellipsis",
  color: "#000c",
  marginLeft: "3px",
  marginRight: "2px",
  boxSizing: "border-box",
  fontWeight: "600",
  fontSize: "14px",
  wordWrap: "break-word",
});
export const DocumentView = styled("a")({
  textDecoration: "none",
  backgroundColor: "rgb(17, 85, 95)",
  color: "white",
  padding: "5px 10px",
});
