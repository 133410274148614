import {
  Divider,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { TitleStyled } from "..";
import _ from "lodash";
import {
  allow10DigitsForNumber,
  allowedDigitsForPhoneNumber,
  allowOnlyAlphabetsNoSpace,
  allowOnlyAlphabetsWithSpace,
  allowTwoNamesWithSpace,
  allowValidPhoneNumber,
  convertNumberToWord,
  preventWhiteSpaceInInput,
} from "../../../HelperFunctions/HelperFunctions";
import { Controller } from "react-hook-form";

const DetailsCard = ({
  Title,
  fields,
  showTitle = true,
  register,
  setValue,
  formKey,
  errors,
  trigger,
  watch,
  control,
}) => {
  const [formState, setFormState] = useState(fields);
  const [numToWord, setNumToWord] = useState(0);

  useEffect(() => {
    setNumToWord(convertNumberToWord(watch(`${formKey}.annualIncome`) || 0));
  }, [watch(`${formKey}.annualIncome`)]);

  const handleChange = (index, fieldName) => (event) => {
    // preventWhiteSpaceInInput(event)
    if (fieldName === "mobileNumber") {
      allow10DigitsForNumber(event);
    }
    if (fieldName === "firstName" || fieldName === "lastName") {
      allowTwoNamesWithSpace(event);
    }
    if (fieldName === "emailId") {
    }

    if (fieldName === "annualIncome") {
      setNumToWord(convertNumberToWord(event.target.value));
    }

    const updatedFormState = [...formState];
    updatedFormState[index] = {
      ...updatedFormState[index],
      value: event.target.value,
    };
    setFormState(updatedFormState);
    setTimeout(() => {
      trigger(`${formKey}.${fieldName}`);
    }, 100);
  };
  useEffect(() => {
    setFormState(fields);
  }, [fields]);

  useEffect(() => {
    formState?.forEach((field) => {
      setValue(`${formKey}.${field.name}`, field.value);
    });
  }, [formState]);

  const today = new Date();
  const eighteenYearsAgo = new Date(
    today.getFullYear() - 18,
    today.getMonth(),
    today.getDate()
  );
  return (
    <>
      <Grid container sx={{ margin: "10px", marginTop: "1.5rem" }} spacing={2}>
        {showTitle && (
          <Grid item xs={12}>
            <TitleStyled color="black" fontSize="1.25rem" fontWeight="bold">
              {Title}
            </TitleStyled>
          </Grid>
        )}

        <Grid item xs={12} sm={10}>
          <Grid container spacing={4}>
            {formState?.map((field, index) => {
              return (
                <>
                  {field.type !== "hidden" ? (
                    <Grid
                      item
                      xs={6}
                      sm={4}
                      md={3}
                      alignItems={"center"}
                      justifyContent={"center"}
                    >
                      {field.type === "select" ? (
                        <FormControl fullWidth>
                          <InputLabel
                            style={{
                              color: "black",
                              fontWeight: "bold",
                              transform: "unset",
                              fontSize: "0.75rem",
                            }}
                          >
                            {field.label}
                          </InputLabel>
                          <CustomSelect
                            style={{ paddingTop: "2px" }}
                            {...register(`${formKey}.${field.name}`)}
                            variant="standard"
                            autoWidth
                            onChange={handleChange(index, field.name)}
                            placeholder="Select"
                            label={field.label}
                            value={formState[index]?.value || " "}
                          >
                            {field.options?.map((option) => (
                              <MenuItem key={option} value={option}>
                                {option}
                              </MenuItem>
                            ))}
                          </CustomSelect>
                          {errors[formKey]?.[field.name] && (
                            <p style={{ color: "red", fontSize: "0.75rem" }}>
                              {errors[formKey][field.name].message}
                            </p>
                          )}
                        </FormControl>
                      ) : field?.type === "date" ? (
                        <>
                          <Controller
                            name={`${formKey}.${field.name}`}
                            control={control}
                            // defaultValue={
                            //   new Date().toISOString().split("T")[0]
                            // }
                            render={({ field }) => (
                              <StyledTextField
                                {...field}
                                size="medium"
                                variant="standard"
                                label="Select DOB"
                                type="date"
                                fullWidth
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                inputProps={{
                                  min: "1960-01-01",
                                  max: eighteenYearsAgo
                                    .toISOString()
                                    .split("T")[0],
                                  style: {
                                    MozAppearance: "textfield",
                                  },
                                }}
                                InputProps={{
                                  sx: {
                                    "& input[type=number]": {
                                      MozAppearance: "textfield",
                                      "&::-webkit-outer-spin-button": {
                                        WebkitAppearance: "none",
                                        margin: 0,
                                      },
                                      "&::-webkit-inner-spin-button": {
                                        WebkitAppearance: "none",
                                        margin: 0,
                                      },
                                    },
                                  },
                                }}
                                onChange={(e) => {
                                  field.onChange(e); // Update react-hook-form state
                                  handleChange(index, field.name)(e); // Custom logic
                                }}
                              />
                            )}
                          />
                          {errors[formKey]?.[field.name] && (
                            <p style={{ color: "red", fontSize: "0.75rem" }}>
                              {errors[formKey][field.name].message}
                            </p>
                          )}
                        </>
                      ) : (
                        <>
                          <StyledTextField
                            size="medium"
                            variant="standard"
                            type={field.type}
                            label={field.label}
                            InputLabelProps={{ shrink: true }}
                            value={formState[index]?.value}
                            onChange={handleChange(index, field.name)}
                            fullWidth
                            InputProps={{
                              inputProps: {
                                style: {
                                  MozAppearance: "textfield",
                                },
                              },
                              sx: {
                                "& input[type=number]": {
                                  MozAppearance: "textfield",
                                  "&::-webkit-outer-spin-button": {
                                    WebkitAppearance: "none",
                                    margin: 0,
                                  },
                                  "&::-webkit-inner-spin-button": {
                                    WebkitAppearance: "none",
                                    margin: 0,
                                  },
                                },
                              },
                            }}
                          />
                          {field.name === "annualIncome" &&
                            watch(`${formKey}.annualIncome`) > 0 && (
                              <p style={{ fontSize: "0.75rem" }}>{numToWord}</p>
                            )}
                          {errors[formKey]?.[field.name] && (
                            <p style={{ color: "red", fontSize: "0.75rem" }}>
                              {errors[formKey][field.name].message}
                            </p>
                          )}
                        </>
                      )}
                    </Grid>
                  ) : (
                    <StyledTextField
                      {...register(`${formKey}.${field.name}`)}
                      size="medium"
                      variant="standard"
                      type={field.type}
                      label={field.label}
                      InputLabelProps={{ shrink: true }}
                      value={formState[index]?.value}
                      onChange={handleChange(index)}
                    />
                  )}
                </>
              );
            })}
          </Grid>
        </Grid>
      </Grid>
      <Divider></Divider>
    </>
  );
};
export default DetailsCard;

const StyledTextField = styled(TextField)({
  "& .MuiInputBase-root": {
    backgroundColor: "transparent",
    color: "#454545",
  },
  "& .MuiInputLabel-root": {
    fontWeight: "bold",
    color: "black",
  },
});

const CustomSelect = styled(Select)(() => ({
  "& .MuiSelect-select": {
    color: "#454545",
  },
}));
