import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import SendRoundedIcon from "@mui/icons-material/SendRounded";
import styled from "styled-components";
import GlobalModal from "../../../components/Modal/modal";
import CommunicationLog from "../../../components/CommunicationLog/CommunicationLog";
import { useGetStageListing } from "../service";
import { LiaEdit } from "react-icons/lia";

const ClaimCard = ({ claimDataListing }) => {
  const navigate = useNavigate();

  const [modalOpen, setModalOpen] = useState({
    open: false,
    data: "",
  });

  const [policyNo, setPolicyNo] = useState("");
  const [id, setId] = useState("");

  const handleClose = () => {
    setModalOpen({
      open: false,
      data: "",
    });
  };

  const { data: stageData } = useGetStageListing(id);
  const stageList = stageData?.data?.data;

  const handleDocClick = (status) => {
    if (status === "Approved" || status === "Rejected") {
      window.open(claimDataListing?.letterUpload, "_blank");
    } else window.open(claimDataListing?.authorizationLetter, "_blank");
  };

  return (
    <Card
      variant="outlined"
      sx={{
        marginBottom: "10px",
        borderRadius: "10px",
        boxShadow: " 0px 0px 10px 0px #0000001F",
      }}
    >
      <CardContent
        style={{
          fontSize: ".75rem",
          paddingBottom: "16px",
        }}
      >
        <Grid container gap={"10px"}>
          <StyledDivWrapper>
            <Grid item>
              <StyledTag variant="1">LOB: </StyledTag>
              <StyledTagName variant="1">
                {claimDataListing?.lobName || "N/A"}
              </StyledTagName>
            </Grid>
            <Grid item>
              <StyledTag variant="1">Date Submitted: </StyledTag>
              <StyledTagName variant="1">
                {claimDataListing?.created_at || "N/A"}
              </StyledTagName>
            </Grid>
          </StyledDivWrapper>
          <StyledDiv>
            <StyledBoxContainer>
              <Grid item sm={4}>
                <StyledBox>
                  <StyledImgWrapper>
                    <StyledImg
                      src={claimDataListing?.companyLogo}
                      alt="Company Logo"
                    />
                  </StyledImgWrapper>
                  <div>
                    <Typography
                      sx={{
                        color: "#000",
                        fontSize: "12px",
                        lineHeight: "20px",
                        fontWeight: "600",
                      }}
                    >
                      {/* {policy["Ic Name"] || "- - -"} */}
                      {claimDataListing?.lobName} Insurance
                    </Typography>
                    <Typography
                      sx={{
                        color: "#777",
                        fontSize: "12px",
                        lineHeight: "18px",
                      }}
                    >
                      Policy No: {claimDataListing?.policyNo}
                    </Typography>
                  </div>
                </StyledBox>
              </Grid>
              <Grid
                item
                xs={1.5}
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  gap: "2px",
                }}
              >
                <StyledTag variant="2">
                  {claimDataListing?.lobId === "Car" ? "IDV" : "Coverage"}
                </StyledTag>
                <StyledTagName variant="2">
                  {(claimDataListing?.coverage &&
                    `₹ ${claimDataListing?.coverage}`) ||
                    `N/A`}
                </StyledTagName>
              </Grid>
              <Grid
                item
                xs={1.5}
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  gap: "2px",
                }}
              >
                <StyledTag variant="2">Balance Cover</StyledTag>
                <StyledTagName variant="2">
                  {claimDataListing?.balanceSuminsured
                    ? `₹ ${claimDataListing.balanceSuminsured}`
                    : claimDataListing?.coverage
                    ? `₹ ${claimDataListing.coverage}`
                    : "N/A"}
                </StyledTagName>
              </Grid>
              <Grid
                item
                xs={1.5}
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  gap: "2px",
                }}
              >
                <StyledTag variant="2">Claim amount</StyledTag>
                <StyledTagName variant="2">
                  {(claimDataListing?.totalClaimamount &&
                    `₹ ${claimDataListing?.totalClaimamount}`) ||
                    "N/A"}
                </StyledTagName>
              </Grid>
              <Grid
                item
                xs={1.5}
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  gap: "2px",
                }}
              >
                <StyledTag variant="2">Settled Amount</StyledTag>
                <StyledTagName variant="2">
                  {(claimDataListing?.settledAmount &&
                    `${claimDataListing?.settledAmount}`) ||
                    "N/A"}
                </StyledTagName>
              </Grid>
            </StyledBoxContainer>
            <Grid
              item
              xs={1.5}
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-end",
              }}
            >
              <StatusContainer claimStatus={claimDataListing?.claimStatus}>
                <StatusDot status={claimDataListing?.claimStatus}></StatusDot>
                {claimDataListing?.claimStatus || "N/A"}
              </StatusContainer>
            </Grid>
          </StyledDiv>

          {/* <Grid item xs={3} style={{ textAlign: "left" }}>
            <div
              style={{
                fontSize: ".8rem",
                fontWeight: "600",
                color: "#999",
                letterSpacing: ".5px",
                lineHeight: "15px",
                display: "inline",
              }}
            >
              Plan Type:
            </div>
            <div
              style={{
                fontWeight: "600",
                display: "inline",
                marginLeft: "3px",
              }}
            >
              {claimDataListing?.planType?.label || "N/A"}
            </div>
          </Grid> */}
          <StyledDiv>
            <Grid
              item
              xs={"auto"}
              style={{
                display: "flex",
                alignItems: "center",
                gap: "20px",
              }}
            >
              {Object.entries(claimDataListing.additionalKeys).map(
                ([key, value]) => (
                  <StyledBox
                    key={key}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "10px",
                    }}
                  >
                    <div>
                      <StyledTag variant="3">{` ${key}:` || "N/A"}</StyledTag>
                      <StyledTagName variant="3">
                        {`${value} ` || "N/A"}
                      </StyledTagName>
                    </div>
                  </StyledBox>
                )
              )}
            </Grid>

            <Grid
              item
              // xs={1.5}
            >
              {/* <StatusContainer claimStatus={claimDataListing?.claimStatus}>
              <StatusDot status={claimDataListing?.claimStatus}></StatusDot>
              {claimDataListing?.claimStatus || "N/A"}
            </StatusContainer> */}

              {claimDataListing?.claimStatus === "Intimated" && (
                <StyledButton
                  variant="outlined"
                  onClick={() => {
                    if (claimDataListing?.claimStatus === "Intimated") {
                      navigate(
                        `/edit-claim?TransactionId=${claimDataListing?.transaction_id}&mongoId=${claimDataListing?.draftreference}&lob=${claimDataListing?.lobId1}`
                      );
                    }
                  }}
                >
                  <LiaEdit size={"12.5px"} />
                  <span>Edit Claim</span>
                </StyledButton>
              )}
            </Grid>
            {["Approved", "Rejected", "AL issued"].includes(
              claimDataListing?.claimStatus
            ) && (
              <Grid item xs={"auto"}>
                <div style={{ display: "flex", justifyContent: "flex-end" }}>
                  <span
                    onClick={() => {
                      handleDocClick(claimDataListing?.claimStatus);
                    }}
                    style={{
                      marginRight: "5px",
                      fontWeight: "700",
                      color: "#4373eb",
                      cursor: "pointer",
                      textDecoration: "underline",
                    }}
                  >
                    {`${
                      claimDataListing?.claimStatus === "Approved"
                        ? "Approved"
                        : claimDataListing?.claimStatus === "AL issued"
                        ? "Authorization"
                        : "Rejected"
                    }`}{" "}
                    Document
                  </span>
                </div>
              </Grid>
            )}
          </StyledDiv>
          <StyledDiv>
            <div style={{ width: "100%" }}>
              <Grid item xs={12}>
                <div
                  style={{
                    fontWeight: "600",
                    marginBottom: "6px",
                  }}
                >
                  Recent Communication
                </div>
              </Grid>
              <Grid
                item
                xs={6}
                style={{
                  display: "flex",
                  gap: "5px",
                }}
              >
                <SendRoundedIcon
                  style={{
                    color: "#4373eb",
                    height: "20px",
                    width: "20px",
                    margin: "5px",
                  }}
                />
                <div
                  style={{
                    fontWeight: "600",
                    display: "flex",
                    flexDirection: "column",
                    gap: "3px",
                  }}
                >
                  Your Claim Stage is {claimDataListing?.claimStatus || "N/A"}
                  <br />
                  {moment(claimDataListing?.updated_at).format(
                    "DD/MM/YYYY h:mm A"
                  )}
                  <br />
                  <Box>{claimDataListing?.stage}</Box>
                  {/* Commented as per git issue #7391 */}
                  {/* <StatusContainer claimStatus={claimDataListing?.claimStatus}>
                    <StatusDot
                      status={claimDataListing?.claimStatus}
                    ></StatusDot>
                    {claimDataListing?.claimStatus || "N/A"}
                  </StatusContainer> */}
                </div>
              </Grid>
            </div>
            <Grid
              item
              xs={6}
              style={{
                textAlign: "left",
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-end",
                justifyContent: "flex-end",
              }}
            >
              <Button
                sx={{
                  fontSize: "11px",
                  fontWeight: "600",
                  color: "#4373EB",
                  cursor: "pointer",
                  textTransform: "none",
                  padding: "5px 8px",
                }}
                onClick={() => {
                  setModalOpen({
                    open: true,
                    data: "",
                  });
                  setId(claimDataListing?._id);
                  setPolicyNo(claimDataListing?.policyNo);
                }}
              >
                View Communication History
              </Button>
            </Grid>
          </StyledDiv>
        </Grid>
      </CardContent>

      <GlobalModal
        title={
          <StyledHeader>
            <div
              style={{
                width: "32px !important",
                height: "32px !important",
                display: "flex",
                alignItems: "center",
                backgroundColor: "#35C17B",
                borderRadius: "15px",
                marginRight: "10px",
              }}
            >
              <SendRoundedIcon
                style={{
                  color: "white",
                  height: "20px",
                  width: "20px",
                  margin: "5px",
                }}
              />
            </div>

            <TitleSpan>Claim Communication for Policy</TitleSpan>
            <TitleSpan small={true}>&nbsp;{policyNo}</TitleSpan>
          </StyledHeader>
        }
        width={"60%"}
        color={"#444444"}
        noPadding={true}
        open={modalOpen.open}
        onClose={handleClose}
      >
        <CommunicationLog
          // filePreview={trailOpen.data}
          setModalOpen={setModalOpen}
          data={stageList}
          popup={true}
        />
      </GlobalModal>
    </Card>
  );
};

export default ClaimCard;

const StyledDivWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 11px;
  background-color: #f0f0f0;
  border-radius: 4px;
  padding: 5px 10px;
`;

const StyledDiv = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

const StyledBoxContainer = styled.div`
  width: 100%;
  display: flex;
  gap: 10px;
  align-items: center;
`;

const StyledBox = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

const StyledImgWrapper = styled.div`
  width: 110px;
  height: 55px;
  border: 1px solid #cccccc;
  border-radius: 4px;
  padding: 5px;
`;

const StyledImg = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
`;

const StyledTag = styled.span`
  font-weight: 600;
  color: #999;
  letter-spacing: 0.5px;
  line-height: 15px;
  ${({ variant }) =>
    variant === "2"
      ? `
      text-align: center;
      font-size: 12px;
`
      : variant === "3"
      ? `font-size: 11px;`
      : `font-size: 12px;`}
`;

const StyledTagName = styled.span`
  font-weight: 600;
  font-size: 12px;
  ${({ variant }) =>
    variant === "2"
      ? `text-align: center;`
      : variant === "3"
      ? `
       font-size: 11px;
       margin-left: 5px;
      `
      : `
    margin-left: 3px;
  `}
`;

const StyledButton = styled.button`
  font-size: 11px;
  border: 1px solid rgb(67, 115, 235);
  border-radius: 5px;
  color: rgb(67, 115, 235);
  cursor: pointer;
  background-color: white;
  padding: 5px 8px;
  display: flex;
  align-items: center;
  gap: 3px;
`;

const StatusContainer = styled.div`
  display: flex;
  align-items: center;
  height: 20px;
  font-weight: 700;
  width: max-content;
  padding: 7px 13px;
  // margin-left: ${({ isMargin }) => (isMargin ? "30px" : "100px")};
  // margin-bottom: ${({ isMargin }) => (isMargin ? "0px" : "10px")};
  // margin-top: ${({ isMargin }) => (isMargin ? "10px" : "8px")};
  text-align: center;
  gap: 6px;
  border-radius: 5px;
  font-size: 11px;
  letter-spacing: 0.5px;
  opacity: 0px;
  background-color: ${({ claimStatus }) => {
    switch (claimStatus) {
      case "Approved":
        return "#beff9b";
      case "Intimated":
        return "#ffe3a6";
      case "In Progress":
        return "#EDEDFC";
      case "Pending":
        return "#95d4ffab";
      case "Rejected":
        return "rgb(255 201 203)";
      case "AL issued":
        return "rgb(223 161 227 / 63%)";
      case "Out Standing":
        return "#00000013";
      case "Query Raised":
        return "#ffe3a6";
      default:
        return "#fff";
    }
  }};
  color: ${({ claimStatus }) => {
    switch (claimStatus) {
      case "Approved":
        return "#1a9a5d";
      case "Intimated":
        return "#ffbc00";
      case "In Progress":
        return "#5E5ADB";
      case "Pending":
        return "rgb(67 113 228)";
      case "Rejected":
        return "rgb(246 9 9)";
      case "AL issued":
        return "rgb(161 59 211)";
      case "Out Standing":
        return "#000";
      case "Query Raised":
        return "#ffbc00";
      default:
        return "#E1FCEF";
    }
  }};
`;

const StatusDot = styled.div`
  height: 5px;
  width: 5px;
  border-radius: 50%;
  background-color: ${({ status }) => {
    switch (status) {
      case "Approved":
        return "#1a9a5d";
      case "Intimated":
        return "#ffbc00";
      case "In Progress":
        return "#5E5ADB";
      case "Pending":
        return "rgb(67 113 228)";
      case "Rejected":
        return "rgb(246 9 9)";
      case "AL issued":
        return "rgb(161 59 211)";
      case "Out Standing":
        return "#000";
      case "Query Raised":
        return "#ffbc00";

      default:
        return "#E1FCEF";
    }
  }};
`;

export const Heading = styled(Box)`
  font-size: ${({ small }) => (small ? "14px" : "24px")};
  color: #011340;
  width: 100%;
  font-weight: ${({ small }) => (small ? "400" : "600")};
  margin-bottom: ${({ small }) => small && "15px"};
`;

export const MainCard = styled(Card)`
  padding: 14px;
  border-radius: 15px !important;
`;

export const FilterTagContainer = styled.div`
  display: flex;
`;

export const FilterHR = styled.hr`
  margin: 0px 0px 15px 0;
  border: 1px solid #3300ff;
  opacity: 30%;
`;

export const StyledMainDiv = styled.div`
  border-radius: 10px;
  padding: 10px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
`;
export const StyledHeader = styled.div`
  display: flex;
  align-items: center;
  // padding-bottom: 10px;
  // margin-bottom: 10px;
`;

export const TitleSpan = styled.span`
  font-size: 20px;
  font-weight: ${({ small }) => (small ? "700" : "400")};
  line-height: 19.53px;
  letter-spacing: 0.02em;
  color: "#444444";
`;

export const StyledStepper = styled.div`
  // border:1px solid yellow;
  padding: 10px;
`;

export const CommsButton = styled(Button)`
  // font-weight: 600;
  // color: #4373eb;
  // cursor: pointer;
  // // font-size: 10px;
  // height: fit-content;
  // background-color: transparent;
  // border: none;
  // border-radius: 5px;
  // opacity: 0.9;
  // &:hover {
  //   background-color: #2283c3;
  //   color: #fff;
  // }
`;

// export const Heading = styled(Box)`
//   font-size: ${({ small }) => (small ? "14px" : "24px")};
//   color: #011340;
//   width: 100%;
//   font-weight: ${({ small }) => (small ? "400" : "600")};
//   margin-bottom: ${({ small }) => small && "15px"};
// `;

// export const MainCard = styled(Card)`
//   padding: 14px;
//   border-radius: 15px !important;
// `;

// export const FilterTagContainer = styled.div`
//   display: flex;
// `;

// export const FilterHR = styled.hr`
//   margin: 0px 0px 15px 0;
//   border: 1px solid #3300ff;
//   opacity: 30%;
// `;

// export const StyledMainDiv = styled.div`
//   border-radius: 10px;
//   padding: 10px;
//   box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
// `;
// export const StyledHeader = styled.div`
//   display: flex;
//   align-items: center;
//   padding-bottom: 10px;
//   margin-bottom: 10px;
// `;

// export const TitleSpan = styled.span`
//   font-size: 20px;
//   font-weight: ${({ small }) => (small ? "700" : "400")};
//   line-height: 19.53px;
//   letter-spacing: 0.02em;
//   color: "#444444";
// `;

// export const StyledStepper = styled.div`
//   // border:1px solid yellow;
//   padding: 10px;
// `;
