import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import httpClient from "../../api/httpClient";
import Swal from "sweetalert2";

export const useGetCustomerRecordsById = ({ id, fetchFamily }) => {
  const { data, isLoading } = useQuery(
    ["customer_record", id, fetchFamily],
    () => {
      return httpClient(
        `/customer_records/List/${id}?display_childs=${fetchFamily}`,
        { method: "GET" }
      );
    },
    {
      enabled: Boolean(id),
    }
  );
  return { data, isLoading };
};
export const useGetVehicleDetailsByCustomerId = (id) => {
  const { data, isLoading } = useQuery(
    {
      queryKey: ["customer_vehicle_details", id],
      queryFn: () => {
        return httpClient(`customer_vehicle_details/listByCustomerId/${id}`);
      },
    },
    {
      enabled: Boolean(id),
    }
  );
  return { data, isLoading };
};

export const useAddFamilyMember = () => {
  const queryClient = useQueryClient();
  const { data, mutate, isSuccess, isLoading } = useMutation(
    (payload) => {
      return httpClient("customer_records/add", {
        method: "POST",
        data: payload,
      });
    },
    {
      onSuccess: (response) => {
        if (response.success) {
          queryClient.invalidateQueries("customer_record");
          Swal.fire({
            position: "center",
            icon: "success",
            title: "Family Member Added Successfully",
            showConfirmButton: false,
            timer: 1000,
          });
        } else {
          Swal.fire({
            icon: "error",
            title: "Failed",
            text: response?.message || "Unknown error occurred",
          });
        }
      },
    }
  );
  return { data, mutate, isSuccess, isLoading };
};
export const useAddVehicle = () => {
  const queryClient = useQueryClient();
  const { mutate, isSuccess } = useMutation(
    (payload) => {
      return httpClient("/customer_vehicle_details/add", {
        method: "POST",
        data: payload,
      });
    },
    {
      onSuccess: (response) => {
        if (response.success) {
          queryClient.invalidateQueries("customer_vehicle_details");
          Swal.fire({
            position: "center",
            icon: "success",
            title: "Vehicle Added Successfully",
            showConfirmButton: false,
            timer: 1000,
          });
        } else {
          Swal.fire({
            icon: "error",
            title: "Failed",
            text: response?.message || "Unknown error occurred",
          });
        }
      },
    }
  );
  return { mutate, isSuccess };
};
export const useDeleteFamilyMember = () => {
  const queryClient = useQueryClient();
  const { mutate } = useMutation(
    (id) => {
      return httpClient(`customer_records/delete/${id}`, { method: "DELETE" });
    },
    {
      onSuccess: (response) => {
        if (response?.data?.status) {
          Swal.fire({
            icon: "success",
            title: response?.data?.message,
          });
          queryClient.invalidateQueries("customer_record");
        }
      },
    }
  );
  return { mutate };
};
export const useDeleteVehicle = () => {
  const queryClient = useQueryClient();
  const { mutate } = useMutation(
    (id) => {
      return httpClient(`customer_vehicle_details/delete/${id}`, {
        method: "DELETE",
      });
    },
    {
      onSuccess: (response) => {
        if (response.success) {
          queryClient.invalidateQueries("customer_vehicle_details");
          Swal.fire({
            position: "center",
            icon: "success",
            title: "Vehicle Deleted Successfully",
            showConfirmButton: false,
            timer: 1000,
          });
        } else {
          Swal.fire({
            icon: "error",
            title: "Failed",
            text: response?.message || "Unknown error occurred",
          });
        }
        // queryClient.invalidateQueries("customer_vehicle_details");
      },
    }
  );
  return { mutate };
};
export const useUpdateCustomerDetails = () => {
  const queryClient = useQueryClient();
  const { mutate, isSuccess, isLoading } = useMutation(
    ({ id, payload }) => {
      return httpClient(`customer_records/update/${id}`, {
        method: "PUT",
        data: payload,
      });
    },
    {
      onError: (error) => {
        console.error(error);
      },
      onSuccess: (response) => {
        if (response.success) {
          queryClient.invalidateQueries("customer_record");
          Swal.fire({
            position: "center",
            icon: "success",
            title: response.message,
            showConfirmButton: false,
            timer: 1000,
          });
        } else {
          Swal.fire({
            icon: "error",
            title: "Failed",
            text: response?.message || "Unknown error occurred",
          });
        }
      },
    }
  );
  return { mutate, isSuccess, isLoading };
};
export const useUpdateVehicleDetailsById = () => {
  const queryClient = useQueryClient();
  const { mutate } = useMutation(
    ({ id, payload }) => {
      return httpClient(`customer_vehicle_details/update/${id}`, {
        method: "PUT",
        data: payload,
      });
    },
    {
      onError: (error) => {
        console.log(error);
      },
      onSuccess: (response) => {
        if (response.success) {
          queryClient.invalidateQueries("customer_vehicle_details");
          Swal.fire({
            position: "center",
            icon: "success",
            title: "Vehicle Updated Successfully",
            showConfirmButton: false,
            timer: 1000,
          });
        } else {
          Swal.fire({
            icon: "error",
            title: "Failed",
            text: response?.message || "Unknown error occurred",
          });
        }
      },
    }
  );
  return { mutate };
};
export const useGetManufacturerData = (lobid) => {
  const { data, isLoading } = useQuery({
    enabled: Boolean(lobid),
    queryKey: ["getManufacturerData", lobid],
    queryFn: () =>
      httpClient("lob_manufacturer/get-manufacturer", {
        method: "POST",
        data: { lobid },
      }),
    enabled: lobid ? true : false,
  });
  return { data, isLoading };
};
// console.log('hkk)
export const useGetModelDataForCustomer = () => {
  const { data, mutate, isLoading } = useMutation((payload) => {
    return httpClient("/lob_manufacturer/get-model", {
      method: "POST",
      data: payload,
    });
  });
  return { data, mutate, isLoading };
};

export const useGetVersionDataForCustomer = () => {
  const { data, mutate, isLoading } = useMutation((payload) => {
    return httpClient("/lob_manufacturer/get-version", {
      method: "POST",
      data: payload,
    });
  });
  return { data, mutate, isLoading };
};

export const useGetFuelData = () => {
  const { data, mutate, isLoading } = useMutation((payload) => {
    return httpClient("/lob_manufacturer/get-version-fuel-type", {
      method: "POST",
      data: payload,
    });
  });
  return { data, mutate, isLoading };
};
