import { useNavigate } from "react-router-dom";
import httpClient from "../../api/httpClient";
import { useMutation } from "@tanstack/react-query";
import Swal from "sweetalert2";

export const useLoginCustomer = () => {
  const navigate = useNavigate();
  const { mutate, data } = useMutation(
    (payload) => {
      return httpClient("auth/login", { method: "POST", data: payload });
    },
    {
      onSuccess: (data) => {
        if (data?.data?.status) {
          sessionStorage.setItem("token", data?.data?.data);
          sessionStorage.setItem(
            "userInfo",
            JSON.stringify({
              firstName: data.data.firstName,
              lastName: data.data.lastName,
              emailId: data.data.emailId,
              brokerLogo: data.data.brokerLogo,
              customerId: data.data.customerId,
            })
          );
          data?.data?.data && navigate("/");
        } else {
          Swal.fire({ text: data?.data?.message, icon: data?.data?.errortype });
        }
      },
    }
  );
  return { mutate, data };
};

export const useForgetPassword = () => {
  const navigate = useNavigate();
  const { mutate, data } = useMutation(
    (payload) => {
      return httpClient(`auth/forget-passwords`, {
        method: "POST",
        data: payload,
      });
    },
    {
      onSuccess: (data) => {
        Swal.fire({
          text: data?.data?.message,
          icon: data.success ? "success" : "error",
        });
        if (data.success) {
          navigate("/login");
        }
      },
    }
  );
  return { mutate, data };
};

export const useResetPassword = () => {
  const navigate = useNavigate();
  const { mutate, data } = useMutation(
    (payload) => {
      return httpClient("auth/reset-password", {
        method: "POST",
        data: payload,
      });
    },
    {
      onSuccess: (data) => {
        Swal.fire({
          text: data?.data?.message,
          icon: data.success ? "success" : "error",
        });
        if (data.success) {
          navigate("/login");
        }
      },
    }
  );
  return { mutate, data };
};
