import { useQuery } from "@tanstack/react-query";
import { useDispatch } from "react-redux";

import { useLocation } from "react-router-dom";
import httpClient from "../api/httpClient";
// import { setActualFieldDataAction } from "../modules/Brokerage/Brokerage.slice";

const useGetClaimIntimationFieldData = (lobId) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const CLAIM_CREATE = location.pathname === "/raise-claims";
  const { data, isLoading, isFetching, isError } = useQuery(
    {
      queryKey: ["proposal_view_labels", lobId],
      queryFn: () =>
        httpClient(
          CLAIM_CREATE
            ? `claim_field_segregation/list/${lobId}?isIntimationField=Y&isBasicDetails=N`
            : `claim_field_segregation/list/${lobId}?isIntimationField=Y&isBasicDetails=N`,

          { method: "GET" }
        ),
      enabled: !!lobId,
    }
    // {
    //   onSuccess: (data) => {
    //     dispatch(setActualFieldDataAction(data));
    //   },
    // }
  );

  return { data, isLoading, isFetching, isError };
};

export default useGetClaimIntimationFieldData;
