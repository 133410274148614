import { Box, Container, Grid } from "@mui/material";
import React, { useState } from "react";
import { useFormContext } from "react-hook-form";
import { Button, DatePickerInput, FilePicker, Input } from "../../components";
import { Heading } from "../../styles/StyledComponents";
import {
  allowOnlyNumbers,
  allowValidPhoneNumber,
} from "../../HelperFunctions/HelperFunctions";
import CustomRadioButtonGroup from "../../components/CustomRadioButtonGroup/CustomRadioButtonGroup";
import { CustomLabel } from "../../components/form-components/input/style";
import Dropdown from "../../components/Dropdown/Dropdown";
import { formatPayloadForEndorsement } from "../../helperFunction";
import { useAddClaims, useFinalSubmit } from "./service";
import { useSearchParams } from "react-router-dom";
import DateAndTimePicker from "../../components/form-components/dateAndTimePicker/DateAndTimePicker";
const RaiseClaims = () => {
  const {
    handleSubmit,
    register,
    control,
    watch,
    unregister,
    trigger,
    setValue,
    errors,
    reset,
  } = useFormContext();
  const [searchParams] = useSearchParams();
  const transactionId = searchParams.get("TransactionId");
  const lob = searchParams.get("lob");
  console.log({ lob });
  const { mutateAsync, data } = useAddClaims();
  const { mutate, data: data11 } = useFinalSubmit({ reset });
  const [files, setFiles] = useState([]);
  const [files1, setFiles1] = useState([]);
  const [files2, setFiles2] = useState([]);
  const [files3, setFiles3] = useState([]);
  const onSubmit = async (formdata) => {
    const abc = {
      ...formdata,
      policyNo: formdata?.policyNo?.value,
      accountType: formdata?.accountType?.value,
      firDocument: formdata?.firDocument?.[0]?.base64String,
      drivingLicense: formdata?.drivingLicense?.[0]?.base64String,
      rcDocument: formdata?.rcDocument?.[0]?.base64String,
      otherCertificate: formdata?.otherCertificate?.[0]?.base64String,
    };
    const payload = {
      lobId: lob,
      transaction_id: +transactionId,
      stage: "RAISED",
      remark: "",
      data: abc,
    };
    console.log("abc", abc);
    if (payload) {
      const abcd = await mutateAsync(payload);
      console.log({ abcd });
      mutate(abcd?.data?.data);
    }
  };
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid
        container
        columnSpacing={{
          lg: 2,
        }}
        rowSpacing={{
          lg: 2,
        }}
        style={{ backgroundColor: "#ffffff", paddingRight: "15px", marginTop: "15px" }}
      >
        <Grid item xs={12} sm={12} md={6} lg={10}>
          <Box
            style={{
              fontSize: "24px",
              color: "#011340",
              height: "36px",
              width: "100%",
              lineHeight: "36px",
              // paddingLeft: "0px",
              fontWeight: "600",
              marginBottom: "15px",
            }}
          >
            Basic Details
          </Box>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={4}>
          <Input
            themeType={"theme1"}
            label="Nature of Claim"
            name="natureOfclaim"
            id="natureOfclaim"
            placeholder="Nature of Claim"
            autoComplete="none"
            required={false}
            inputRef={register("natureOfclaim")}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={4}>
          <Dropdown
            themeType={"theme1"}
            label="Third Party Loss"
            name="policyNo"
            id="policyNo"
            selectObj={[
              { value: "Y", label: "yes" },
              { value: "N", label: "no" },
            ]}
            placeholder="Third Party Loss"
            autoComplete="none"
            required={false}
            inputRef={register("policyNo")}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={4}>
          <Input
            themeType={"theme1"}
            label="Accident City Pincode"
            name="accidentPincode"
            id="accidentPincode"
            placeholder="Accident City Pincode"
            onChange={allowOnlyNumbers}
            maxlength={6}
            autoComplete="none"
            required={false}
            inputRef={register("accidentPincode")}
          />
        </Grid>

        <Grid item xs={12} sm={12} md={6} lg={4}>
          <Input
            themeType={"theme1"}
            label="Accident State"
            name="accidentState"
            id="accidentState"
            placeholder="Accident State"
            autoComplete="none"
            required={false}
            inputRef={register("accidentState")}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={4}>
          <Input
            themeType={"theme1"}
            label="Accident City"
            name="accidentCity"
            id="accidentCity"
            placeholder="Accident City"
            autoComplete="none"
            required={false}
            inputRef={register("accidentCity")}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={4}>
          <DateAndTimePicker
            themeType={"theme1"}
            label="Date and Time of Accident"
            name="dateTime"
            id="dateTime"
            placeholder="Date and Time of Accident"
            autoComplete="none"
            required={false}
            inputRef={register("dateTime")}
          />
        </Grid>
        
        <Grid item xs={12} sm={12} md={6} lg={4}>
          <Input
            themeType={"theme1"}
            label="IDV"
            name="coverage"
            id="coverage"
            placeholder="Enter IDV"
            autoComplete="none"
            required={false}
            inputRef={register("coverage")}
          />
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={6}
          lg={4}
          style={{
            // display: "flex",
            width: "100%",
            alignItems: "center",
            gap: "15px",
            paddingLeft: "12px",
          }}
        >
          <CustomLabel>{"Was Vehicle Parked?"}</CustomLabel>
          <CustomRadioButtonGroup
            style={{
              margin: "-10px 0 0 10px",
              width: "100%",
            }}
            control={control}
            name="vehicleParked"
            // label="Gender"
            options={[
              { value: "Y", label: "yes" },
              { value: "N", label: "no" },
            ]}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={4}>
          <Input
            themeType={"theme1"}
            label="Brief Description of Accident"
            name="accidentBrief"
            id="accidentBrief"
            placeholder="Brief Description of Accident"
            autoComplete="none"
            required={false}
            inputRef={register("accidentBrief")}
          />
        </Grid>

        <Grid item xs={12} sm={12} md={6} lg={10}>
          <Box
            style={{
              fontSize: "24px",
              color: "#011340",
              height: "36px",
              width: "100%",
              lineHeight: "36px",
              // paddingLeft: "0px",
              fontWeight: "600",
              marginBottom: "15px",
            }}
          >
            Contact Details
          </Box>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={4}>
          <Input
            themeType={"theme1"}
            label="Claim Intimated By"
            name="claimIntimatedbby"
            id="claimIntimatedbby"
            placeholder="Claim Intimated By"
            autoComplete="none"
            required={false}
            inputRef={register("claimIntimatedbby")}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={4}>
          <Input
            themeType={"theme1"}
            label="Intimator Name"
            name="intimatorName"
            id="intimatorName"
            placeholder="Intimator Name"
            autoComplete="none"
            required={false}
            inputRef={register("intimatorName")}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={4}>
          <Input
            themeType={"theme1"}
            label="Intimator Mobile No."
            name="intimatorMobile"
            id="intimatorMobile"
            placeholder="Intimator Mobile No."
            autoComplete="none"
            required={false}
            inputRef={register("intimatorMobile")}
            onChange={allowValidPhoneNumber}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={4}>
          <Input
            themeType={"theme1"}
            label="Customer's Current Mobile No."
            name="currentMobile"
            id="currentMobile"
            placeholder="Customer's Current Mobile No."
            autoComplete="none"
            required={false}
            inputRef={register("currentMobile")}
            onChange={allowValidPhoneNumber}
          />
        </Grid>

        <Grid item xs={12} sm={12} md={6} lg={4}>
          <Input
            themeType={"theme1"}
            label="Customer's Current Email ID"
            name="customerMaild"
            id="customerMaild"
            placeholder="Customer's Current Email ID"
            autoComplete="none"
            required={false}
            inputRef={register("customerMaild")}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={4}>
          <Input
            themeType={"theme1"}
            label="Driver Name"
            name="driverName"
            id="driverName"
            placeholder="Driver Name"
            autoComplete="none"
            required={false}
            inputRef={register("driverName")}
          />
        </Grid>

        <Grid item xs={12} sm={12} md={6} lg={10}>
          <Box
            style={{
              fontSize: "24px",
              color: "#011340",
              height: "36px",
              width: "100%",
              lineHeight: "36px",
              // paddingLeft: "0px",
              fontWeight: "600",
              marginBottom: "15px",
            }}
          >
            Bank Details for Reimbursement
          </Box>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={4}>
          <Input
            themeType={"theme1"}
            label="Payer Name"
            name="payeeName"
            id="payeeName"
            placeholder="Payee Name"
            autoComplete="none"
            required={false}
            inputRef={register("payeeName")}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={4}>
          <Input
            themeType={"theme1"}
            label="Permanent Account Number"
            name="accountNumber"
            id="accountNumber"
            placeholder="Permanent Account Number"
            autoComplete="none"
            required={false}
            inputRef={register("accountNumber")}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={4}>
          <Input
            themeType={"theme1"}
            label="Name Of Bank"
            name="nameBank"
            id="nameBank"
            placeholder="Name Of Bank"
            autoComplete="none"
            required={false}
            inputRef={register("nameBank")}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={4}>
          <Input
            themeType={"theme1"}
            label="Name Of Branch"
            name="branchName"
            id="branchName"
            placeholder="Name Of Branch"
            autoComplete="none"
            required={false}
            inputRef={register("branchName")}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={4}>
          <Input
            themeType={"theme1"}
            label="Bank Address"
            name="bankAddress"
            id="bankAddress"
            placeholder="Bank Address"
            autoComplete="none"
            required={false}
            inputRef={register("bankAddress")}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={4}>
          <Input
            themeType={"theme1"}
            label="City Name"
            name="cityName"
            id="cityName"
            placeholder="City Name"
            autoComplete="none"
            required={false}
            inputRef={register("cityName")}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={4}>
          <Input
            themeType={"theme1"}
            label="PIN Code"
            name="pinCode"
            id="pinCode"
            placeholder="PIN Code"
            autoComplete="none"
            required={false}
            inputRef={register("pinCode")}
            maxlength={6}
            onChange={allowOnlyNumbers}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={4}>
          <Input
            themeType={"theme1"}
            label="IFSC Code"
            name="ifscCode"
            id="ifscCode"
            placeholder="IFSC Code"
            autoComplete="none"
            required={false}
            inputRef={register("ifscCode")}
            onChange={allowOnlyNumbers}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={4}>
          <Dropdown
            themeType={"theme1"}
            label="Type Of Account"
            name="accountType"
            id="accountType"
            selectObj={[
              { label: "Current Account", value: "Current Account" },
              { label: "Saving Account", value: "Saving Account" },
            ]}
            placeholder="Type Of Account"
            autoComplete="none"
            required={false}
            inputRef={register("accountType")}
          />
        </Grid>
        <Container style={{ margin: "30px 0" }}>
          <FilePicker
            name="firDocument"
            label={"FIR Document"}
            control={control}
            files={files}
            setFiles={setFiles}
            setValue={setValue}
            watch={watch}
            trigger={trigger}
            unregister={unregister}
            isRequired={false}
            isReadOnly={false}
          />
        </Container>
        <Container style={{ margin: "30px 0" }}>
          <FilePicker
            name="drivingLicense"
            label="Driving License"
            control={control}
            files={files1}
            setFiles={setFiles1}
            setValue={setValue}
            watch={watch}
            trigger={trigger}
            unregister={unregister}
            isRequired={false}
            isReadOnly={false}
          />
        </Container>
        <Container style={{ margin: "30px 0" }}>
          <FilePicker
            name="rcDocument"
            label="RC Document"
            control={control}
            files={files2}
            setFiles={setFiles2}
            setValue={setValue}
            watch={watch}
            trigger={trigger}
            unregister={unregister}
            isRequired={false}
            isReadOnly={false}
          />
        </Container>
        <Container style={{ margin: "30px 0" }}>
          <FilePicker
            name="otherCertificate"
            label="Other Certificate"
            control={control}
            files={files3}
            setFiles={setFiles3}
            setValue={setValue}
            watch={watch}
            trigger={trigger}
            unregister={unregister}
            isRequired={false}
            isReadOnly={false}
          />
        </Container>

        {/* BUTTON */}
        <Grid
          container
          lg={12}
          sx={12}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Button
            type={"submit"}
            style={{
              width: "12rem",
              height: "2.5rem",
              border: "1px solid",
              borderRadius: "4px",
              // padding: "6px 12px",
              marginBottom: "15px !important"
            }}
            //   onClick={() => navigate("/all-policies")}
            bgColor={"#4373EB"}
          >
            Submit
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};
export default RaiseClaims;
